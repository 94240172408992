<template>
  <div class="word-bank">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" />
        <span>我的词库</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="page-content">
        <el-tabs v-model="activeName" @tab-click="handleClick" class="m-t-12 m-b-12">
          <el-tab-pane label="错词词典" name="first">
            <ErrWords v-if="activeName=='first'"/>
          </el-tab-pane>
          <el-tab-pane label="敏感词词典" name="second">
            <SensitiveWords />
          </el-tab-pane>
        </el-tabs>
        
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import ErrWords from "@/views/newCheck//components/ErrWords.vue";
import SensitiveWords from "@/views/newCheck//components/SensitiveWords.vue";
export default {
  components: {
    Breadcrumb, ErrWords, SensitiveWords
  },
  data() {
    return {
      routerList: ["发文排查", "我的词库"],
      activeName: 'first'
    }
  },
  mounted() {
    this.activeName = this.$route.query.type
  },
  methods: {
    back() {
      this.$router.back()
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style lang="scss" scoped>
.word-bank {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .page-content {
      width: 100%;
      height: auto;
      margin-top: 16px;
      background: #ffffff;
      padding: 0 24px 16px;
      border-radius: 4px;
    }
  }
}
</style>
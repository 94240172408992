// 我的词库
import request from "../request.js";
export function getList(data) {
  return request({
    url: '/module-web/orgInaccuracyWords/list',
    method: 'post',
    data
  })
}
// 添加词
export function addKeyWord(data) {
  return request({
    url: '/module-web/orgInaccuracyWords/save',
    method: 'post',
    data
  })
}
// 删除词
export function delKeyWord(data) {
  return request({
    url: '/module-web/orgInaccuracyWords/delete',
    method: 'post',
    data
  })
}
// 清空词库
export function clearKeyWord(data) {
  return request({
    url: '/module-web/orgInaccuracyWords/emptyWords',
    method: 'post',
    data
  })
}
//从word导入
export function importFile(data) {
  return request({
    url: '/module-web/orgInaccuracyWords/import',
    method: 'post',
    data
  })
}
//从word导出
export function exportFile(data) {
  return request({
    url: '/module-web/orgInaccuracyWords/export',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
